@import "../../styles/global.scss";
@import "../../styles/variables.scss";

.root {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.head {
  width: 100%;
}

.container {
  max-width: 1240px;
  width: calc(100% - 140px);
  border-radius: 40px;
  background-color: $theme;
  margin: 40px 70px;
  @media (max-width: 992px) {
    width: 100%;
    max-width: 100%;
    margin: 0;
    border-radius: 0;
  }
}

.child {
  width: 100%;
  padding: 40px 70px;
  @media (max-width: 768px) {
    padding: 50px 40px 60px;
  }
}
