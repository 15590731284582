@font-face {
  font-family: "Regular";
  src: url("./Poppins-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Bold";
  src: url("./Poppins-Bold.woff2") format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "SemiBold";
  src: url("./Poppins-SemiBold.ttf") format("ttf");
  font-display: swap;
}
@font-face {
  font-family: "Light";
  src: url("./Poppins-Light.ttf") format("ttf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
