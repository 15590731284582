@import "../../styles/variables.scss";

.root {
  width: 100%;
  height: 2px;
  background-color: $light-grey;
  position: relative;
}

.child {
    height: 2px;
    position: absolute;
    width: 20%;
    background-color: $blue;
}
