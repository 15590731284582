@import "../../styles/variables.scss";

.root {
  height: 100%;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  margin-top: 50px;
}

.about {
  width: 90%;
  display: flex;
  justify-content: space-around;
  @media (max-width: 1200px) {
    justify-content: space-between;
  }
  @media (max-width: 992px) {
    width: 100%;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.profile {
  width: 20%;
  > img {
    height: 250px;
    width: 250px;
    border-radius: 50%;
    border: 18px solid $white;
  }
  @media (max-width: 768px) {
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
  }
}

.social {
  width: 250px;
  display: flex;
  justify-content: space-evenly;
  margin-top: 20px;
  fill: $grey;
  > a {
    all: unset;
  }
}

.me {
  width: 50%;
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    width: 100%;
    margin-top: 50px;
    justify-content: center;
    align-items: center;
  }
}

.eyeBrow {
  opacity: 0.4;
}

.name {
  font-weight: 600;
  font-size: 48px;
  @media (max-width: 576px) {
    font-size: 40px;
  }
  line-height: 1;
}

.detail {
  opacity: 0.7;
  margin-top: 20px;
  > a {
    color: $blue;
    text-decoration: none;
  }
}

.btns {
  display: flex;
  @media (max-width: 468px) {
    flex-direction: column;
  }
  > * {
    margin-top: 30px;
    padding: 10px 30px;
    border-radius: 30px;
    text-decoration: none;
    background-color: $white;
    outline: none;
    cursor: pointer;
    margin-right: 10px;
  }
}

.contact {
  border: 2px solid $light-grey;
  color: $grey;
  box-shadow: 0 10px 10px -8px $light-grey;
  text-align: center;
  &:hover {
    background-color: $light-grey;
  }
}

.resume {
  border: 2px solid $light-blue;
  color: $blue;
  box-shadow: 0 10px 10px -8px $light-grey;
  &:hover {
    background-color: $light-blue;
  }
}

.recommendations {
  width: 100%;
  margin-top: 100px;
}

.recomTitle {
  display: inline-flex;
  flex-direction: column;
  margin-bottom: 20px;
  > h2 {
    margin: 0;
  }
}

.recomItems {
  display: flex;
  justify-content: space-evenly;
  @media (min-width: 1201px) {
    > div {
      flex: 0 43%;
    }
  }
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.nav {
  height: 3px;
  width: 28px;
  background-color: $grey;
  margin-right: 5px;
  cursor: pointer;
}

.current {
  background-color: $blue;
  width: 38px;
}

svg {
  cursor: pointer;
}
