@import "../../../styles/variables.scss";

.root {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-bottom: 20px;
  padding: 40px 70px;
}

.logo {
  width: 50%;
  @media (max-width: 768px) {
    width: 90%;
  }
}

.menu {
  display: flex;
  justify-content: space-between;
  width: 50%;
}

.item {
  opacity: 0.5;
  > a {
    text-decoration: none;
    color: unset;
  }
}

.current {
  opacity: 1;
}
