@import "../../styles/variables.scss";

.root {
  padding-bottom: 25px;
  padding-top: 25px;
  display: flex;
  flex-direction: column;
  border-left: 1px solid $light-grey;

  padding-left: 20px;
}

.info {
  display: flex;
}

.moreInfo {
  //
}

.year {
  margin-left: -40px;
  background-color: $white;
  padding: 0 10px;
  border: 2px solid $blue;
  border-radius: 30px;
}

.head {
  font-size: 12px;
  color: $grey;
  opacity: 0.7;
  margin-left: 10px;
  display: flex;
  align-items: center;
}

.title {
  margin: 10px 0 7px;
  font-weight: 600;
}

.desc {
  color: $grey;
  margin: 0;
}
