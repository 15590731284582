.root {
  height: 100%;
  position: relative;
}

.img {
  margin: auto;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: max-content;
  width: max-content;
}
