@import "../../styles/variables.scss";

.root {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  width: 80%;
}

.head {
  display: flex;
  justify-content: space-between;
  * {
    margin: 0;
  }
}

.progressContainer {
  width: 100%;
  background-color: white;
  border: 1px solid $light-grey;
  height: 5px;
  border-radius: 20px;
}

.progress {
  background-color: $blue;
  height: 3px;
}
