$white: #fff;
$light-blue: #ecf1f9;
$blue: #007ced;
$theme: #fafafa;
$light-grey: #d5d5d5;
$light-gray-1: #f2f2f2;
$grey-font: #333333;
$grey: #666;
$border-color: #eeeeee;

// font
$regular: "Regular";
$bold: "Bold";
