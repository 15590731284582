@import "./variables.scss";
@import "./fonts/style.scss";

body {
  font-family: $regular;
  background-color: $light-blue;
  background-image: url("../images/bg.png");
  background-size: cover;
  margin: 0;
}

* {
  box-sizing: border-box;
}

h1 {
  font-family: $bold;
}

p {
  font-family: $regular;
}
