@import "../../styles/variables.scss";

.root {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.img {
  height: 100%;
  width: 100%;
  border-radius: 10px;
}

.container {
  position: relative;
  width: 32%;
  height: 250px;
  margin-bottom: 20px;
  border: 1px solid $light-grey;
  border-radius: 10px;
  cursor: pointer;
  @media (max-width: 1040px) {
    width: 45%;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
  &:hover {
    box-shadow: -7px 17px 27px -10px rgba(0, 0, 0, 0.75);
    transition-duration: 200ms;
    > .img {
      opacity: 0.5;
    }
    > .btns {
      display: flex;
    }
  }
}

.btns {
  position: absolute;
  top: 0;
  display: none;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-around;
}

.gitHub,
.live {
  background-color: $white;
  padding: 10px 20px;
  color: $grey-font;
  border-radius: 20px;
  text-decoration: none;
  font-family: $bold;
  border: 1px solid $light-grey;
}
